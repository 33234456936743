<template>
  <div class="p-1">
    <div class="h-20 py-3 flex items-center border-black-200 p-6 mt-5">
      <div class="flex w-full">
        <button
          :class="$colors.primary"
          class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
          @click="RouterBack()"
        >
          <i class="material-icons">chevron_left</i>
        </button>

        <div class="text-xl font-bold text-black-600">Details</div>
      </div>
    </div>

    <div-table
      :config="configList"
      :data="content"
      :loading="loading"
      :paginate="paginate"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      @paginatation="paginatation"
    ></div-table>
  </div>
</template>

<script>
import config from "./configStock";
export default {
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      configList: config.list,
      loading: false,
      content: [],
      // currentUser: {},
      limit: 10,
      // idWharhouse:null,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
    };
  },

  async mounted() {
    //await this.getUser();
    // await this.getwharhouseId();
    if (this.currentUser && this.currentUser.type == "Seller") {
      let userI = this.configList.columns
        .map(function (e) {
          return e.name;
        })
        .indexOf("user");
      if (userI != -1) this.configList.columns.splice(userI, 1);
    }
    if (this.idWharhouse) await this.getStockHistories({ limit: this.limit });
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      // await this.getwharhouseId();
      // await this.getStockHistories({ limit: this.limit });
    },
    idWharhouse: async function (oldVal, newVal) {
      this.idWharhouse = oldVal;
      await this.getStockHistories({ limit: this.limit });
    },
  },
  methods: {
    RouterBack: function () {
      this.$router.back();
    },
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },

    async getStockHistories(filters) {
      filters.product = this.$route.params.id;
      filters.warehouse = this.idWharhouse._id;

      const res = await this.$server.search("stockHistories", filters);

      /* if (res.content.results) {
        this.content = res.content.results;
        let stockHistories = this.content;
        for (let i in stockHistories) {
          stockHistories[i].user = stockHistories[i].user.email;
          stockHistories[i].date = stockHistories[i].date.split("T")[0];
          stockHistories[i].product = stockHistories[i].product.name;
          stockHistories[i].quantity = stockHistories[i].quantity;
          stockHistories[i].reason = stockHistories[i].reason;
        }

        this.content = stockHistories;
        
      } else this.content = []*/

      if (res.content.results) {
        this.content = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
        for (let i in this.content) {
          if (
            this.content[i] &&
            this.content[i].warehouse &&
            this.content[i].warehouse.country
          )
            this.content[i].country = this.content[i].warehouse.country;
        }
      } else {
        this.content = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async refresh() {
      this.getStockHistories({ limit: this.limit });
    },

    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      const filters = {
        page: page,
        limit: this.limit,
      };
      this.getStockHistories(filters);
    },
  },
};
</script>
